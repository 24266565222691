<template>
    <div>
        <div class="frm" ref="load">
            <el-table ref="multipleTable" :data="selectList" :row-key="getRowKeys" tooltip-effect="dark"
                @selection-change="handleSelectChange" :reserve-selection="true">
                <el-table-column prop="id"
                    type="selection" :reserve-selection="true"
                    width="60">
                </el-table-column>
                <!-- <el-table-column prop="id" width="60" label="选择">
                    <template slot-scope="scope">
                        <el-radio v-model="singleId" :label="scope.row.id"><i></i></el-radio>
                    </template>
                </el-table-column> -->
                <el-table-column prop="id" label="商品信息">
                    <template slot-scope="scope">
                        <div class="goodsInfo flex">
                            <div style="width: 90px;height: 90px;margin-right:20px">
                                <div v-if="scope.row.photo_render" class="bg_img"
                                    :style="{backgroundImage: 'url(' +imgUrl+scope.row.photo_render[0]+'!width_250px'+ ')'}"></div>
                            </div>
                            <div class="right flex-1">
                                <div class="name ellipsis1">
                                    <b @click="goto('/factory/zysp/detail/'+scope.row.id)">{{scope.row.name}}</b>
                                </div>
                                <div style="margin-bottom:6px">
                                    型号：{{scope.row.module_no}}
                                </div>
                                <div class="tag">
                                    <span v-if="scope.row.set">{{scope.row.set.name}}</span>
                                    <span v-if="scope.row.set && scope.row.style">|</span>
                                    <span v-if="scope.row.style">{{scope.row.style.name}}</span>
                                    <span v-if="scope.row.style && scope.row.module_no">|</span>
                                    <span v-if="scope.row.module_no">{{scope.row.module_no}}</span>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="name" label="商品名称">
                    <template slot-scope="scope">
                        <a @click="goto('/factory/zysp/detail/'+scope.row.id)">{{scope.row.name}}</a>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        <div class="page flex">
            <div class="flex-1">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.page" :page-size="queryOpt.pagesize" :total="total"></el-pagination>
            </div>
            <!-- <div v-if="allSelectedId" style="line-height:32px;padding:0 20px;font-size:15px;">已选中
                <span style="color:#409EFF;">{{allSelectedId.length}}</span>
            </div> -->
        </div>
        <div class="flex flex-justify-around" style="padding: 30px 140px 0;">
            <el-button @click="hide('cancel')" style="width:76px;" size="small" type="primary" plain>取消</el-button>
            <el-button @click="addGoods()" style="width:76px;" size="small" type="primary">确定</el-button>
        </div>
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { IMG_URL_PRE } from '@/config';
    import { getSelfList, multiUpdateselfProduct } from '@/service/factory';
    import { listFxProduct, multiUpdateFxProduct } from '@/service/sharer';
    export default {
        props:['set_id','list'],
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                selfIndex:null,
                queryOpt:{
                    price_type:[0,1,2],
                    page:1,
                    pagesize:10,
                },
                total:10,
                selectList:[],
                comp:null,
                allSelectedId:[],
                singleId:null,
                // list: null, // 用于回显列表选中的数据
            }
        },
        watch: {
            'queryOpt.page': {
                handler() {
                    this.debounceRefreshItems();
                },
                deep: true
            },
            set_id: {
                handler() {
                    this.showGoodList();
                },
                deep: true
            },
        },
        created() {
            this.comp = this.$store.state.loginUser.comp;
            this.debounceRefreshItems = debounce(this.refreshItems, 500);
            // this.refreshItems();
        },
        mounted() {
            this.showGoodList();
        },
        methods: {
            async showGoodList() {
                // const param = {
                //     custom_cat_no: this.set_id,
                //     page: 1,
                //     pagesize: 999
                // };
                // let res = null;
                // if (this.comp.comp_type==1) {
                //     res = await getSelfList(param);
                // } else if (this.comp.comp_type==3) {
                //     res = await listFxProduct(param);
                // }
                // this.list = res.list;
                console.log(666,this.list,this.$refs.multipleTable);
                this.$refs.multipleTable.clearSelection();
                // 回填多选数据
                if (this.list && this.list.length>0) {
                    this.list.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
                this.refreshItems()
            },
            goto(path) {
                this.$router.push(path);
            },
            refreshItems() {
                // const _loading = this.$loading({
                //     target:this.$refs.load,
                //     lock: true,
                //     text: "拼命加载中....",
                // });
                if (this.comp.comp_type==1) {
                    let obj = {
                        has_sku: 1, // 1有sku，0没有sku
                        page: 1, 
                        pagesize: 10,
                    }
                    // 自有商品列表
                    getSelfList(obj).then(rst=>{
                        this.total=rst.total;
                        this.selectList = rst.list;
                        console.log(this.selectList);
                        // _loading.close();
                    }).catch(err => {
                        // _loading.close();
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else if (this.comp.comp_type==3) {
                    listFxProduct(this.queryOpt).then(rst=>{
                        this.total=rst.total;
                        this.selectList = rst.list;
                        console.log(this.selectList);
                        // _loading.close();
                    }).catch(err => {
                        // _loading.close();
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
            },
            getRowKeys(row) { 
                return row.id;
            },
            //确定选中
            handleSelectChange(rows) {
                console.log(rows);
                //将选中赋值到回显和传参数组
                this.templateRadio = rows;
                this.allSelectedId = [];
                if (rows) {
                rows.forEach(row => {
                    this.allSelectedId.push(row.id);
                });
                }
                console.log(this.allSelectedId);
            },
            // 从分销商品库添加商品到商品分类
            addGoods() {
                let param = {rows: []};
                this.allSelectedId.forEach((e)=>{
                    param.rows.push({
                        custom_cat_no:this.set_id,
                        id:e,
                    })
                })
                if (this.comp.comp_type==1) {
                    multiUpdateselfProduct(param).then(rst => {
                        console.log('multiUpdateselfProduct',rst);
                        this.hide();
                        this.$message.success("添加成功");
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                } else if (this.comp.comp_type==3) {
                    multiUpdateFxProduct(param).then(rst => {
                        console.log('multiUpdateFxProduct',rst);
                        this.hide();
                        this.$message.success("添加成功");
                    }).catch(err => {
                        console.log(err);
                        this.$message.error(err.message);
                    });
                }
            },
            hide(type) {
                this.$emit('hide',type);
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    .frm {
        height:480px;
        /* border-top:1px solid #EBEEF5; */
        /* border-bottom:1px solid #EBEEF5; */
        overflow:hidden;
    }
    div.frm>>> .el-table {
        border-top: 1px solid #EBEEF5;
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
    }
    .goodsInfo .right {
        text-align: left;
    }
    .goodsInfo .right .name {
        font-size: 18px;
        color: #202020;
        margin-bottom: 8px;
    }
    .goodsInfo .right .tag span {
        color: #808080;
        margin-right: 10px;
    }
    .page {
        padding:10px;
        border-left:1px solid #EBEEF5;
        border-right:1px solid #EBEEF5;
        border-bottom:1px solid #EBEEF5;
    }
</style>